import React from 'react'
import {
  Breadcrumbs,
  Education,
  ServiceContact,
  Hero,
  Page,
} from '@components'
import { buildLocalizedPath } from '@utils/functions'

const meta = {
  title: 'Edukacija | Protupožarna zaštita - FSB d.o.o.',
  description: 'Tvrtka FSB d.o.o. radi sprječavanja neželjenih događaja koji se odnose na montažu pasivne protupožarne zaštite osmislila je edukaciju za radnike monterskih tvrtki, projektante i nadzorne inženjere',
  image: require('@assets/images/edu.jpg'),
}

const Homepage = ({ pageContext }) => (
  <Page
    isHomepage
    meta={meta}
    locale={pageContext.locale}
  >
    <Breadcrumbs
      links={[
        { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
        { link: buildLocalizedPath('/services/', pageContext.locale), name: 'header.link.services'},
        { link: buildLocalizedPath('/services/education/', pageContext.locale), name: 'service.3.hero.subtitle'},
      ]}
    />
    <Hero
      title="service.3.hero.title"
      subtitle="service.3.hero.subtitle"
      description="service.3.hero.description"
    />
    <Education locale={pageContext.locale} />
    <ServiceContact id="edukacija" />
  </Page>
)

export default Homepage